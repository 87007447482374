//SITEFinanceiro
// const LayoutFront = () => import('../pages/LayoutFront');

//painel
const LayoutPainel = () => import('../pages/LayoutPainel');
const LoginPainel = () => import('../pages/painel/Login');
const Register = () => import('../pages/painel/Register');
const RedefinirSenha = () =>
  import('../pages/painel/RedefinirSenha');
const CriarSenha = () =>
  import('../pages/painel/CriarSenha');
const DashboardsPainel = () =>
  import('../pages/painel/Home/DashboardsPainel');
const PainelConsumidores = () =>
  import('../pages/painel/consumidores/Consumidores');
const PainelArquivos = () =>
  import('../pages/painel/Arquivos/Arquivos');
const PainelAdicionarArquivos = () =>
  import('../pages/painel/Arquivos/AdicionarArquivo');
const PainelPrecos = () =>
  import('../pages/painel/precos/PainelPrecos');
const SolicitarPreco = () =>
  import('../pages/painel/precos/SolicitarPrecos');
const PainelOperacoes = () =>
  import('../pages/painel/Operacoes/Operacoes');
const ConfiguracaoPainel = () =>
  import('../pages/painel/Configuracao/Configuracao');
const DescontoGarantido = () =>
  import(
    '../pages/painel/DescontoGarantido/DescontoGarantido'
  );
const RealizarAnalise = () =>
  import(
    '../pages/painel/DescontoGarantido/RealizarAnalise'
  );
const ConsumoContratacao = () =>
  import(
    '../pages/painel/ConsumoContratacao/ConsumoContratacao'
  );
// const Financeiro = () =>
//   import('../pages/painel/Financeiro/Financeiro');
const NotFound = () =>
  import('../pages/painel/NotFound/NotFound');
const Encargos = () =>
  import('../pages/painel/Encargos/Encargos');
const Economia = () =>
  import('../pages/painel/Economia/Economia');
import UsuarioService from '../services/painel/UsuarioService';

const routes = [
  // {
  //   name: 'LayoutSite',
  //   component: LayoutFront,
  //   children: [
  //     {
  //       path: '/',
  //       name: 'HomePageSite',
  //       component: LayoutFront,
  //     },
  //     {
  //       path: '/quemsomos',
  //       name: 'QuemSomos',
  //       component: LayoutFront,
  //     },
  //     {
  //       path: '/valores',
  //       name: 'NossosValores',
  //       component: LayoutFront,
  //     },
  //     {
  //       path: '/nossaHistoria',
  //       name: 'NossaHistoria',
  //       component: LayoutFront,
  //     },
  //   ],
  // },
  {
    path: '/',
    name: 'redirect',
    beforeEnter: (to, from, next) => {
      next('/painel');
    },
  },
  {
    path: '/painel/login',
    name: 'LoginPainel',
    component: LoginPainel,
  },
  {
    path: '/painel/register/:hashClienteId',
    name: 'RegisterPainel',
    component: Register,
  },
  {
    path: '/painel/reset/password/:hash',
    name: 'RedefinirSenha',
    component: RedefinirSenha,
  },
  {
    path: '/painel/create/password/:hash',
    name: 'CriarSenha',
    component: CriarSenha,
  },
  {
    path: '/painel',
    name: 'LayoutPainel',
    component: LayoutPainel,
    children: [
      {
        path: '/painel',
        name: 'DashboardsPainel',
        label: 'Dashboards',
        meta: {
          label: 'Dashboards',
          isAuthProduto: false,
        },
        component: DashboardsPainel,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next, to);
        },
      },
      {
        path: '/painel/consumidores',
        name: 'PainelConsumidores',
        label: 'Consumidores',
        meta: {
          label: 'Consumidores',
          isAuthProduto: false,
        },
        component: PainelConsumidores,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      {
        path: '/painel/arquivos',
        name: 'PainelArquivos',
        label: 'Arquivos',
        meta: {
          label: 'Arquivos',
          isAuthProduto: false,
        },
        component: PainelArquivos,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      {
        path: '/painel/arquivos/adicionar',
        name: 'PainelAdicionarArquivos',
        label: 'Adicionar Arquivo',
        meta: {
          label: 'Adicionar Arquivo',
          isAuthProduto: false,
        },
        component: PainelAdicionarArquivos,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      {
        path: '/painel/precos',
        name: 'PainelPrecos',
        label: 'Painel de Preços',
        meta: {
          label: 'Painel de Preços',
          isAuthProduto: true,
        },
        component: PainelPrecos,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      {
        path: '/painel/precos/solicitar',
        name: 'SolicitarPreco',
        label: 'Solicitar produto personalizado',
        meta: {
          label: 'Solicitar produto personalizado',
          isAuthProduto: true,
        },
        component: SolicitarPreco,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      {
        path: '/painel/EditarCardsPrecos',
        name: 'EditarCardsPrecos',
        label: 'Editar cards de preço',
      },
      {
        path: '/painel/operacoes',
        name: 'PainelOperacoes',
        label: 'Operações',
        meta: {
          label: 'Operações',
          isAuthProduto: false,
        },
        component: PainelOperacoes,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      {
        path: '/painel/descontoGarantido',
        name: 'PainelDescontoGarantido',
        label: 'Desconto Garantido',
        meta: {
          label: 'Desconto Garantido',
          isAuthProduto: true,
        },
        component: DescontoGarantido,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      {
        path: '/painel/descontoGarantido/realizarAnalise',
        name: 'RealizarAnalise',
        label: 'Realizar Análise',
        meta: {
          label: 'Realizar Análise',
          isAuthProduto: true,
        },
        component: RealizarAnalise,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      {
        path: '/painel/descontoGarantido/refazerAnalise/:id',
        name: 'RefazerAnalise',
        label: 'Refazer Análise',
        meta: {
          label: 'Refazer Análise',
          isAuthProduto: true,
        },
        component: RealizarAnalise,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      {
        path: '/painel/configuracao',
        name: 'ConfiguracaoPainel',
        label: 'Configuração',
        meta: {
          label: 'Configuração',
          isAuthProduto: false,
        },
        component: ConfiguracaoPainel,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      {
        path: '/painel/consumoContratacao',
        name: 'PainelConsumoContratacao',
        label: 'Consumo x Contratação',
        meta: {
          label: 'Consumo x Contratação',
          isAuthProduto: false,
        },
        component: ConsumoContratacao,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
      // {
      //   path: '/painel/financeiro',
      //   name: 'PainelFinanceiro',
      //   label: 'Financeiro',
      //   meta: {
      //     label: 'Financeiro',
      //     isAuthProduto: false,
      //   },
      //   component: Financeiro,
      //   beforeEnter: (to, from, next) => {
      //     checkIsLogged(next);
      //   },
      // },
      {
        path: '/painel/encargos',
        name: 'PainelEncargos',
        label: 'Encargos',
        meta: {
          label: 'Encargos',
          isAuthProduto: false,
        },
        component: Encargos,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },

      {
        path: '/painel/economia',
        name: 'PainelEconomia',
        label: 'Economia',
        meta: {
          label: 'Economia',
          isAuthProduto: false,
        },
        component: Economia,
        beforeEnter: (to, from, next) => {
          checkIsLogged(next);
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
];

function checkIsLogged(next, to = null) {
  const usuarioService = new UsuarioService();
  usuarioService
    .checkIsLogged(
      JSON.parse(localStorage.getItem('userSkopos'))
    )
    .then((event) => {
      if (typeof event == 'undefined') {
        next('/painel/login');
      } else {
        localStorage.setItem(
          'userSkopos',
          JSON.stringify(event.data.user)
        );
        localStorage.setItem(
          'acessos',
          JSON.stringify(event.data.acessos)
        );
        
        if(to != null){
          if(to.name == 'DashboardsPainel'){
            if(!isAccess('DashboardsPainel')){
              var acessos = atob(event.data.acessos.replaceAll('"', ''))
              acessos = JSON.parse(acessos)
              if(typeof acessos[0] != 'undefined'){
                next({ name: acessos[0].value})
              }
            }
          }
        }
        next();
      }
    })
    .catch();
}

function isAccess(value) {
  var usuarioAcessos = atob(
    localStorage.getItem('acessos').replaceAll('"', '')
  );

  var regex = new RegExp('master', 'i');
  var acesso = usuarioAcessos.match(regex);

  if (acesso) {
    return true;
  }

  regex = new RegExp('ADM', 'i');
  acesso = usuarioAcessos.match(regex);

  if (acesso) {
    return true;
  }

  if (value == null || value == '') {
    return false;
  }

  regex = new RegExp(value, 'i');
  acesso = usuarioAcessos.match(regex);
  return acesso;
}

export default routes;
