<template>
  <router-view></router-view>
  <notifications
    position="bottom right"
    width="400"
    classes="notificate"
  />
</template>
<script>
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
export default {
  name: 'App',
  created() {
    const firebaseConfig = {
      apiKey: 'AIzaSyBpbI8x4l6jfr7gS8fCUvoxurWBTpxGcZ4',
      authDomain: 'skopos-varejista.firebaseapp.com',
      projectId: 'skopos-varejista',
      storageBucket: 'skopos-varejista.appspot.com',
      messagingSenderId: '79002146183',
      appId: '1:79002146183:web:480146d70c0cbae3dbaf22',
      measurementId: 'G-BZYE4KXWT6',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  },
};
</script>
<style lang="scss">
@import '@/assets/sass/global.scss';

.notificate {
  margin: 0 5px 5px;
  padding: 15px;
  font-size: 20px;
  color: #ffffff;
  border-radius: 0.35rem;

  // default (blue)
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  // types (green, amber, red)
  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }
}
</style>
