

const email = function (value) {
  
  if (!( /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/).test(value)) {
    return 'E-mail inválido';
  }
  return true;
}

export default email
