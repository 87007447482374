

const required = function (value) {
    if (!value || !value.length) {
        return 'Campo obrigatório';
      }
      return true;
}

export default required
