import Instance from '../BaseService'
import notification from '../NotificationService'


export default class UsuarioService {

    register(params){
        return Instance.post('/api/usuarios/register',params)
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }

    delete(userId){
        return Instance.delete('/api/usuarios/delete/'+userId)
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }

    registerMaster(params){
        return Instance.post('/api/usuarios/registerMaster',params)
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }

    update(params){
        return Instance.post('/api/usuarios/update',params)
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }

    login(params){
        return Instance.post('/api/usuarios/login',params)
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }

    checkIsLogged(user){
        Instance.defaults.headers['Authorization'] = localStorage.getItem('tokenSkopos')
        return Instance.post('/api/usuarios/checkIsLogged',{user:user})
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }

    listUsuarios(params){
        Instance.defaults.headers['Authorization'] = localStorage.getItem('tokenSkopos')
        return Instance.get('/api/usuarios/listUsuarios',{params})
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }

    registerGrupoUsuario(params){
        Instance.defaults.headers['Authorization'] = localStorage.getItem('tokenSkopos')
        return Instance.post('/api/usuarios/grupo',params)
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }

    resetPassword(params){
        Instance.defaults.headers['Authorization'] = localStorage.getItem('tokenSkopos')
        return Instance.post('/api/usuarios/resetPassword',params)
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }

    resetSenhaSubmit(params){
        Instance.defaults.headers['Authorization'] = localStorage.getItem('tokenSkopos')
        return Instance.post('/api/usuarios/passwordSave',params)
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }

    listSelect(){
        Instance.defaults.headers['Authorization'] = localStorage.getItem('tokenSkopos')
        return Instance.get('/api/usuarios/listarSelect')
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }


    createSenhaSubmit(params){
        Instance.defaults.headers['Authorization'] = localStorage.getItem('tokenSkopos')
        return Instance.post('/api/usuarios/createPasswordSave',params)
        .then()
        .catch(({ response }) =>{
            const Notification = new notification()
            Notification.showErrorMessage(response)
        })
    }
}